<template>
  <div class="page-tip">
    <img alt="404 error" class="tip-img" src="/img/404.svg" />
    <h1 class="tip-title">{{ t('Don’t know where you are?') }}</h1>
    <span class="tip-description">
      {{ t('We have no idea either:) Try to go back to the main page') }}
    </span>
    <NuxtLink :to="localePath('/')" class="home-btn tac">
      {{ t('To the main page') }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>


.page-tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 41.8rem;
  height: 41.8rem;

  .tip-title {
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.8rem;
    color: var(--surfaces-on-surface);
  }

  .tip-description {
    margin-top: 0.8rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: rgba(229, 237, 255, 0.8);
    max-width: 41.8rem;
    text-align: center;
  }

  .home-btn {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--surfaces-low-container);
    border: 0.1rem solid var(--accent-secondary);
    border-radius: 1.2rem;
    padding: 1.5rem 8.95rem;
    color: var(--accent-secondary);
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}

@media screen and (max-width: 435px) {
  .page-tip {
    width: 34.7rem;
    height: 36rem;

    .tip-img {
      width: 32.3rem;
      height: 21.6rem;
    }

    .tip-title {
      font-size: 2rem;
      line-height: 2.7rem;
    }

    .tip-description {
      font-size: 1.4rem;
      line-height: 2.6rem;
    }

    .home-btn {
      padding: 1.5rem 2.95rem;
    }
  }
}
</style>
